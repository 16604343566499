.landing-logo {
	width: 100%;
}

.container {
	max-width: 100%;
	padding: 0 !important;
	display: inline-block;
}

/* Desktop */
@media (min-width: 0px) and (max-width: 3000px) {
	.container {
		max-width: 100% !important;
	}
}

.cards {
	padding: 7px;
}

.item {
	a {
		/* color: #390879; */
		color: #545454;
	}
}

.ui.card > .image:not(.ui) > img,
.ui.cards > .card > .image:not(.ui) > img {
	height: 200px;
	object-fit: scale-down;
}

.ui.raised.card {
	height: 400px;
	height: fit-content;
}

/* Search Cards */
.search-cards {
	padding: 7px;
}

.ui.raised.card.gamecard-searchitem {
	height: 400px;
	height: fit-content;
}

.ui.raised.card.gamecard-searchitem > .content > .header > .title {
	/* float: left; */
	margin-top: 5px;
}

.ui.raised.card.gamecard-searchitem > .content > .header > .rating {
	/* float: left; */
	margin-top: 5px;
}

.ui.raised.card.gamecard-searchitem
	> .content
	> .header
	> .rating
	> .rating-text {
	font-weight: 500;
}

/* .ui.raised.card.gamecard-searchitem > .extra.content > .rating-text,
.ui.raised.card.gamecard-searchitem > .extra.content > .rating-value {
	color: black;
} */
